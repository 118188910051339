<template>
  <div class="privacy-policy pt-4">
    <h5 class="letter-spacing-2">PRIVACY POLICY</h5>

    <div class="container">
      <div class="row text-start">
        <div class="col-8 offset-2 ">
          <p><strong>WHAT’S IN THESE POLICY?</strong></p>
          <p>
            We are Easetate and our address is at United Kingdom.
            In this privacy notice we will refer to ourselves as ‘we’, ‘us’ or ‘our’.
          </p>

          <p>You can get hold of us in any of the following ways:</p>
          <ol type="a" style="padding-left: 25px;">
            <li>
              <div>
                By e-mailing us at <a class="text-decoration-none" href="mailto:support@easetate.co.uk">
                <font-awesome-icon icon="envelope" /> support@easetate.co.uk
              </a>; or
              </div>
            </li>
            <li>
              <div>
                Via <router-link class="text-decoration-none" to="/contact">contact us</router-link> page.
              </div>
            </li>
          </ol>

          <p>
            We take the privacy, including the security, of personal information we hold about you seriously.
            This privacy notice is designed to inform you about how we collect personal information about you
            and how we use that personal information.  You should read this privacy notice carefully so that
            you know and can understand why and how we use the personal information we collect and hold about you.
          </p>

          <p>
            We do not have a Data Protection Officer, but if you have any questions about this privacy notice
            or issues arising from it then you should contact our support team, who is responsible for matters
            relating to data protection at our organisation, including any matters in this privacy notice.
            You can contact them using the details set out above.
          </p>

          <p>
            We may issue you with other privacy notices from time to time, including when we collect personal
            information from you.  This privacy notice is intended to supplement these and does not override them.
          </p>

          <p>
            We may update this privacy notice from time to time.  This version was last updated on May 2022.
          </p>

          <p>Click on the links below to go straight to more information on each area:</p>

          <div>
            <ul>
              <li><a href="#key_definitions" class="link-info">Key Definitions</a></li>
              <li><a href="#details_of_personal_info" class="link-info">Details of personal information which we collect and hold about you</a></li>
              <li><a href="#details_of_special_info" class="link-info">Details of special information which we collect and hold about you</a></li>
              <li><a href="#details_of_use_personal_info" class="link-info">Details of how and why we use personal information</a></li>
              <li><a href="#details_of_collect_personal_and_special_info" class="link-info">Details of how we collect personal information and special information</a></li>
              <li><a href="#details_of_share_with" class="link-info">Details about who personal information may be share with</a></li>
              <li><a href="#details_of_transfers_outside_eea" class="link-info">Details about transfers to countries outside of the EEA</a></li>
              <li><a href="#details_of_how_long_hold_data" class="link-info">Details about how long we will hold your personal information</a></li>
              <li><a href="#rights_under_data_protection_law" class="link-info">Your Rights Under Data Protection Law</a></li>
              <li><a href="#marketing" class="link-info">Marketing</a></li>
              <li><a href="#complaints" class="link-info">Complaints</a></li>
              <li><a href="#third_party_websites" class="link-info">Third Party Websites</a></li>
            </ul>
          </div>

          <div>
            <ol>
              <!-- 1 -->
              <li id="key_definitions"><strong>KEY DEFINITIONS</strong></li>
                <p>
                  The key terms that we use throughout this privacy notice are defined below, for ease:
                </p>
                <p>
                  <strong>Data Controller: </strong>
                  under UK data protection law, this is the organisation or person responsible for deciding
                  how personal information is collected and stored and how it is used.
                </p>
                <p>
                  <strong>Data Processor: </strong>
                  a Data Controller may appoint another organisation or person to carry out certain tasks in
                  relation to the personal information on behalf of, and on the written instructions of, the
                  Data Controller. (This might be the hosting of a site containing personal data, for example,
                  or providing an email marketing service that facilitates mass distribution of marketing material
                  to a Data Controller’s customer-base.)
                </p>
                <p>
                  <strong>Personal Information: </strong>
                  in this privacy notice we refer to your personal data as ‘personal information’.
                  ‘Personal information’ means any information from which a living individual can be identified.
                  It does not apply to information which has been anonymised.
                </p>
                <p>
                  <strong>Special Information: </strong>
                  certain very sensitive personal information requires extra protection under data protection law.
                  Sensitive data includes information relating to health, racial and ethnic origin, political opinions,
                  religious and similar beliefs, trade union membership, sex life and sexual orientation and also
                  includes genetic information and biometric information.
                </p>
              <!-- 2 -->
              <li id="details_of_personal_info"><strong>DETAILS OF PERSONAL INFORMATION WHICH WE COLLECT AND HOLD ABOUT YOU</strong></li>
                <ol type="I">
                  <li>
                    Set out below are the general categories and details of retention periods in relation to those
                    categories (see section 8 below for more details about retention) and in each case the types of
                    personal information which we collect, use and hold about you:
                  </li>
                  <div class="row">
                    <table>
                      <tr>
                        <th>General Category</th>
                        <th>Types of Personal Data in that category</th>
                        <th>Retention Periods</th>
                      </tr>
                      <tr>
                        <td>Identity Information</td>
                        <td>
                          This is information relating to your identity such as your name (including any previous
                          names and any titles which you use), gender, marital status and date of birth
                        </td>
                        <td>Indefinitely</td>
                      </tr>
                      <tr>
                        <td>Contact Information</td>
                        <td>
                          This is information relating to your contact details such as e-mail address, addresses, telephone numbers
                        </td>
                        <td>Indefinitely</td>
                      </tr>
                      <tr>
                        <td>Account Information</td>
                        <td>
                          This is information relating to your account with us (including username and password)
                        </td>
                        <td>Indefinitely</td>
                      </tr>
                      <tr>
                        <td>Payment Information</td>
                        <td>
                          This is information relating to the methods by which you provide payment to us such as
                          bank account details, credit or debit card details and details of any payments
                          (including amounts and dates) which are made between us.
                        </td>
                        <td>
                          Indefinitely for payment details (amounts, dates)
                        </td>
                      </tr>
                      <tr>
                        <td>Transaction Information</td>
                        <td>
                          This is information relating to transactions between us such as details of the
                          services and/or digital content provided to you and any returns details.
                        </td>
                        <td>Indefinitely</td>
                      </tr>
                      <tr>
                        <td>Survey Information</td>
                        <td>
                          This is information which we have collected from you or which you have provided to us
                          in respect of surveys and feedback.
                        </td>
                        <td>Indefinitely</td>
                      </tr>
                      <tr>
                        <td>Marketing Information</td>
                        <td>
                          This is information relating to your marketing and communications preferences.
                        </td>
                        <td>Indefinitely</td>
                      </tr>
                      <tr>
                        <td>Website, Device and Technical Information</td>
                        <td>
                          This is information about your use of our website and technical data which we collect
                          (including your IP address, the type of browser you are using and the version, the operating
                          system you are using, details about the time zone and location settings on the device and other
                          information we receive about your device)
                        </td>
                        <td>Indefinitely</td>
                      </tr>
                      <tr>
                        <td>Premises and Services Information</td>
                        <td>
                          This is information about premises or services, such as images, addresses, prices,
                          floorplans, other documents, details of the premises, details of the services,
                          occupation provided, areas covered etc.
                        </td>
                        <td>
                          Some of the data will be kept unless advertise is taken off website either by automated
                          service, user himself or our team. Some other data (premises and service specifically related)
                          will be kept indefinitely for historical, statistical and marketing reasons.
                        </td>
                      </tr>
                    </table>
                  </div>
                  <li>
                    The types of personal data we collect about you may differ from person to person,
                    depending on who you are and the relationship between us.
                  </li>
                </ol>
              <!-- 3 -->
              <li id="details_of_special_info"><strong>DETAILS OF SPECIAL INFORMATION WHICH WE COLLECT AND HOLD ABOUT YOU</strong></li>
                <ol type="I">
                  <li>
                    Special information is explained in section 1 above. We do not collect or hold any special
                    information about you.
                  </li>
                  <li>
                    We do not collect information from you relating to criminal convictions or offences.
                  </li>
                </ol>
              <!-- 4 -->
              <li id="details_of_use_personal_info"><strong>DETAILS OF HOW AND WHY WE USE PERSONAL INFORMATION</strong></li>
                <ol type="I">
                  <li>
                    We are only able to use your personal information for certain legal reasons set out in
                    data protection law.  There are legal reasons under data protection law other than those listed
                    below, but in most cases,  we will use your personal information for the following legal reasons:
                    <ul>
                      <li>
                        <strong>Contract Reason:</strong> this is in order to perform our obligations to
                        you under a contract we have entered into with you;
                      </li>
                      <li>
                        <strong>Legitimate Interests Reason:</strong> this is where the use of your personal
                        information is necessary for our (or a third party’s) legitimate interests, so long
                        as that legitimate interest does not override your fundamental rights, freedoms or interests.
                      </li>
                      <li>
                        <strong>Legal Obligation Reason:</strong> this is where we have to use your personal
                        information in order to perform a legal obligation by which we are bound; and
                      </li>
                      <li>
                        <strong>Consent Reason:</strong> this is where you have given us your consent to use your
                        personal information for a specific reason or specific reasons.
                      </li>
                    </ul>
                  </li>
                  <li>
                    So that we are able to provide you with services, we will need your personal information.
                    If you do not provide us with the required personal information, we may be prevented from
                    supplying the services to you.
                  </li>
                  <li>
                    It is important that you keep your personal information up to date.  If any of your personal
                    information changes, please contact us as soon as possible to let us know.  If you do not do
                    this then we may be prevented from supplying the services to you.
                  </li>
                  <li>
                    Where we rely on consent for a specific purpose as the legal reason for processing your personal
                    information, you have the right under data protection law to withdraw your consent at any time.
                    If you do wish to withdraw your consent, please contact us using the details set out at the
                    beginning of this notice.  If we receive a request from you withdrawing your consent to a
                    specific purpose, we will stop processing your personal information for that purpose, unless
                    we have another legal reason for processing your personal information, in which case, we will
                    confirm that reason to you.
                  </li>
                  <li>
                    We have explained below the different purposes for which we use your personal information and,
                    in each case, the legal reason(s) allowing us to use your personal information.
                    Please also note the following:
                    <ul>
                      <li>
                        if we use the Legitimate Interests Reason as the legal reason for which we can use your
                        personal information, we have also explained what that legitimate interest is; and
                      </li>
                      <li>
                        for some of the purposes we may have listed more than one legal reason on which we can
                        use your personal information, because the legal reason may be different in different
                        circumstances.  If you need confirmation of the specific legal reason that we are relying
                        on to use your personal data for that purpose, please contact us using the contact details
                        set out at the start of this privacy notice.
                      </li>
                    </ul>
                  </li>
                  <div class="row">
                    <table>
                      <tr>
                        <th>Purpose</th>
                        <th>Legal Reason(s) for using the personal information</th>
                      </tr>
                      <tr>
                        <td>To enrol you as a customer/platform user</td>
                        <td>
                          Contract Reason
                          For contract purposes, so we can have contact with you and be able to identify you as our platform user.

                          Legal Obligation Reason
                          In order to offer you services and/or digital content which helps us to develop our business
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To process your order, which includes taking payment from you, advising you of any updates
                          in relation to your order or any enforcement action against you to recover payment.
                        </td>
                        <td>
                          Contract Reason

                          Legal Obligation Reason
                          In order to take payments and transfer money between us/tenant/host/service provider and Stripe accounts.

                          In order to recover money which, you owe us/tenant/host/service provider.
                        </td>
                      </tr>
                      <tr>
                        <td>To manage our contract with you and to notify you of any changes</td>
                        <td>
                          Contract Reason

                          Legal Obligation Reason
                        </td>
                      </tr>
                      <tr>
                        <td>To comply with audit and accounting matters</td>
                        <td>
                          Legal Obligation Reason
                        </td>
                      </tr>
                      <tr>
                        <td>
                          For record keeping, including in relation to any guarantees or warranties provided as part
                          of the sale of services and/or digital content
                        </td>
                        <td>
                          Contract Reason

                          Legal Obligation Reason
                        </td>
                      </tr>
                      <tr>
                        <td>To improve the services and/or digital content which we supply</td>
                        <td>
                          Legitimate Interests Reason

                          In order to improve the services and/or digital content for future customers and to grow our business
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To recommend and send communications to you about services, and/or digital content that
                          you may be interested in.  More details about marketing are set out in section 11 below
                        </td>
                        <td>
                          Legitimate Interests Reason

                          In order to grow our business

                          Consent Reason
                        </td>
                      </tr>
                      <tr>
                        <td>To ensure the smooth running and correct operation of our platform</td>
                        <td>
                          Legitimate Interests Reason

                          To ensure our platform runs correctly
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To understand how customers and visitors to our website use the website and interact with
                          it via data analysis
                        </td>
                        <td>
                          Legitimate Interests Reason

                          To improve and grow our business, including our website, and to understand our customer’s
                          needs, desires and requirements
                        </td>
                      </tr>
                    </table>
                  </div>
                  <li>
                    Sometimes we may anonymise personal information so that you can no longer be identified from it
                    and use this for our own purposes. In addition, sometimes we may use some of your personal
                    information together with other people’s personal information to give us statistical information
                    for our own purposes.  Because this is grouped together wither other personal information and
                    you are not identifiable from that combined data we are able to use this.
                  </li>
                  <li>
                    Under data protection laws we can only use your personal information for the purposes we have
                    told you about, unless we consider that the new purpose is compatible with the purpose(s) which
                    we told you about. If we want to use your personal information for a different purpose which
                    we do not think is compatible with the purpose(s) which we told you about then we will contact
                    you to explain this and what legal reason is in place to allow us to do this.
                  </li>
                </ol>
              <!-- 5 -->
              <li id="details_of_collect_personal_and_special_info"><strong>DETAILS OF HOW WE COLLECT PERSONAL INFORMATION AND SPECIAL INFORMATION</strong></li>
                <ol type="I">
                  <li>
                    We usually collect Identity Information, Contact Information, Payment Information,
                    Transaction Information, Survey Information, Marketing Information, Special Information;
                    Premises and Services Information directly from you when you fill out a form, survey or
                    questionnaire, purchase/book services from hosts via our platform, contact us by e-mail, or via
                    contact us form or otherwise. This includes the personal information which you provide to
                    us when you subscribe to our mailing list.
                  </li>
                  <li>
                    We may receive some of your personal information from third parties or publicly available sources.
                    This includes:
                    <ul>
                      <li>
                        Contact Information and Payment Information from our selected third-party suppliers, such as Stripe;
                      </li>
                      <li>
                        Website, Device and Technical Information from third parties such as analytics providers (like Google);
                      </li>
                    </ul>
                  </li>
                  <li>
                    We may also receive Website, Device and Technical Information automatically from technologies such
                    as cookies which are installed on our website.  To find out more about these
                    please see our <router-link to="/cookie-policy" class="text-decoration-none">Cookie Policy</router-link>.
                  </li>
                </ol>
              <!-- 6 -->
              <li id="details_of_share_with"><strong>DETAILS ABOUT WHO PERSONAL INFORMATION MAY BE SHARE WITH</strong></li>
                <ol type="I">
                  <li>
                    We may need to share your personal information with other organisations or people.
                    These organisations include:
                    <ol type="a">
                      <li>
                        Other companies in our group (who may might act as joint data controllers or as data processors
                        on our behalf) and who might need access to them in order to provide IT Services and Support
                        in administrative tasks.
                      </li>
                      <li>
                        Third parties who are not part of our group.  These may include:
                        <ul>
                          <li>
                            Suppliers: such as IT support services, payment providers, administration providers, marketing
                            agencies who are based in the United Kingdom, Europe or United States of America;
                          </li>
                          <li>
                            Government bodies and regulatory bodies: such as HMRC, fraud prevention agencies who are based
                            in the United Kingdom, Europe or United States of America;
                          </li>
                          <li>
                            Our advisors: such as lawyers, accountants, auditors, insurance companies who are based in the
                            United Kingdom, Europe or United States of America;
                          </li>
                          <li>
                            Our bankers who are based in the United Kingdom, Europe or United States of America;
                          </li>
                          <li>
                            Credit Reference Agencies who are based in the United Kingdom, Europe or United States of America;
                          </li>
                        </ul>
                      </li>
                      <li>
                        any organisations which propose to purchase our business and assets in which case we may
                        disclose your personal information to the potential purchaser.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Depending on the circumstances, the organisations or people who we share your personal information
                    with will be acting as either Data Processors or Data Controllers. Where we share your personal
                    information with a Data Processor we will ensure that we have in place contracts, which set out
                    the responsibilities and obligations of us and them, including in respect of security of
                    personal information.
                  </li>
                </ol>
              <!-- 7 -->
              <li id="details_of_transfers_outside_eea"><strong>DETAILS ABOUT TRANSFERS TO COUNTRIES OUTSIDE OF THE EEA</strong></li>
                <ol type="I">
                  <li>
                    If any transfer of personal information by us will mean that your personal information is
                    transferred outside of the EEA then we will ensure that safeguards are in place to ensure
                    that a similar degree of protection is given to your personal information, as is given to
                    it within the EEA and that the transfer is made in compliance with data protection laws
                    (including where relevant any exceptions to the general rules on transferring personal
                    information outside of the EEA which are available to us – these are known as ‘derogations’
                    under  data protection laws).  We may need to transfer personal information outside of
                    the EEA to other organisations within our group or to the third parties listed above in section 6
                    who may be located outside of the EEA.
                  </li>
                  <li>
                    The safeguards set out in data protection laws for transferring personal information
                    outside of the EEA include:
                    <ol type="a">
                      <li>
                        where the transfer is to a country or territory which the EU Commission has approved as
                        ensuring an adequate level of protection;
                      </li>
                      <li>
                        where personal information is transferred to another organisation within our group,
                        under an agreement covering this situation which is known as “binding corporate rules”;
                      </li>
                      <li>
                        having in place a standard set of clauses which have been approved by the EU Commission;
                      </li>
                      <li>
                        compliance with an approved code of conduct by a relevant data protection supervisory
                        authority (in the UK, this is the Information Commissioner’s Office (ICO);
                      </li>
                      <li>
                        certification with an approved certification mechanism;
                      </li>
                      <li>
                        where the EU Commission has approved specific arrangements in respect of certain countries,
                        such as the US Privacy Shield, in relation to organisations which have signed up to it in the USA.
                      </li>
                    </ol>
                  </li>
                </ol>
              <!-- 8 -->
              <li id="details_of_how_long_hold_data"><strong>DETAILS ABOUT HOW LONG WE WILL HOLD YOUR PERSONAL INFORMATION</strong></li>
                <ol type="I">
                  <li>
                    We will only hold your personal data for as long as is necessary. How long is necessary will
                    depend upon the purposes for which we collected the personal information (see section 4 above)
                    and whether we are under any legal obligation to keep the personal information (such as in
                    relation to accounting or auditing records or for tax reasons).  We may also need to keep
                    personal information in case of any legal claims, including in relation to any guarantees
                    or warranties which we have provided with the services and digital content.
                  </li>
                  <li>
                    We have set out above the details of our retention periods for different types of data.
                    You can find them in in section 2 [and also in section 3].
                  </li>
                </ol>
              <!-- 9 -->
              <li id="rights_under_data_protection_law"><strong>YOUR RIGHTS UNDER DATA PROTECTION LAW</strong></li>
                <ol type="I">
                  <li>
                    Under data protection laws you have certain rights in relation to your personal information, as follows:
                    <ol type="a">
                      <li>
                        <strong>Right to request access:</strong> (this is often called ‘subject access’).
                        This is the right to obtain from us a copy of the personal information which we hold about you.
                        We must also provide you with certain other information in response to these requests to help
                        you understand how your personal information is being used.
                      </li>
                      <li>
                        <strong>Right to correction:</strong> this is the right to request that any incorrect
                        personal data is corrected and that any incomplete personal data is completed.
                      </li>
                      <li>
                        <strong>Right to erasure:</strong> (this is often called the “right to be forgotten”).
                        This right only applies in certain circumstances. Where it does apply, you have the right
                        to request us to erase all of your personal information.
                      </li>
                      <li>
                        <strong>Right to restrict processing:</strong> this right only applies in certain circumstances.
                        Where it does apply, you have the right to request us to restrict the processing of your
                        personal information.
                      </li>
                      <li>
                        <strong>Right to data portability:</strong> this right allows you to request us to
                        transfer your personal information to someone else.
                      </li>
                      <li>
                        <strong>Right to object:</strong> you have the right to object to us processing your
                        personal information for direct marketing purposes.  You also have the right to object
                        to us processing personal information where our legal reason for doing so is the
                        Legitimate Interests Reason (see section 4 above) and there is something about your
                        particular situation which means that you want to object to us processing your personal
                        information.  In certain circumstances you have the right to object to processing
                        where such processing consists of profiling (including profiling for direct marketing).
                      </li>
                    </ol>
                  </li>
                  <li>
                    In addition to the rights set out in section 10.1, where we rely on consent as the legal
                    reason for using your personal information, you have the right to withdraw your consent.
                    Further details about this are set out in section 4.5.
                  </li>
                  <li>
                    If you want to exercise any of the above rights in relation to your personal information,
                    please contact us using the details set out at the beginning of this notice.
                    If you do make a request then please note:
                    <ol type="a">
                      <li>
                        we may need certain information from you so that we can verify your identity;
                      </li>
                      <li>
                        we do not charge a fee for exercising your rights unless your request is unfounded or excessive; and
                      </li>
                      <li>
                        if your request is unfounded or excessive then we may refuse to deal with your request.
                      </li>
                    </ol>
                  </li>
                </ol>
              <!-- 10 -->
              <li id="marketing"><strong>MARKETING</strong></li>
                <ol type="I">
                  <li>
                    You may receive marketing from us about similar goods and services, where either you have
                    consented to this, or we have another legal reason by which we can contact you for marketing purposes.
                  </li>
                  <li> <!-- [If you have an account with us, you can login to your account and manage your preferences there too.]
                    [DESCRIBE ANY OTHER METHODS] -->
                    However, we will give you the opportunity to manage how or if we market to you. In any e-mail which
                    we send to you, we provide a link to either unsubscribe or opt-out, or to change your marketing preferences.
                    To change your marketing preferences, and/or to request that we
                    stop processing your personal information for marketing purposes , you can always
                    contact us on the details set out at the beginning of this notice.
                  </li>
                  <li>
                    If you do request that we stop marketing to you, this will not prevent us from sending
                    communications to you which are not to do with marketing (for example in relation to services
                    and digital content which you have purchased from us/through us).
                  </li>
                  <li>
                    We do not pass your personal information on to any third parties for marketing purposes.
                  </li>
                </ol>
              <!-- 11 -->
              <li id="complaints"><strong>COMPLAINTS</strong></li>
                <ol type="I">
                  <li>
                    If you are unhappy about the way that we have handled or used your personal information,
                    you have the right to complain to the UK supervisory authority for data protection, which
                    is the Information Commissioner’s Office (ICO).  Please do contact us in the first instance
                    if you wish to raise any queries or make a complaint in respect of our handling or use of
                    your personal information, so that we have the opportunity to discuss this with you and to
                    take steps to resolve the position. You can contact us using the details set out at the
                    beginning of this privacy notice.
                  </li>
                </ol>
              <!-- 12 -->
              <li id="third_party_websites"><strong>THIRD PARTY WEBSITES</strong></li>
                <ol type="I">
                  <li>
                    Our website may contain links to third party websites. If you click and follow those links
                    then these will take you to the third party website. Those third party websites may collect
                    personal information from you and you will need to check their privacy notices to understand
                    how your personal information is collected and used by them.
                  </li>
                </ol>
            </ol>
            <p>Thank you for visiting Easetate.</p>
          </div>
          <p><strong>Updated in:</strong> May 2022</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style lang="scss">
.privacy-policy {
  ul, ol {
    padding: 6px;
    li {
      padding: 3px;
    }
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}
</style>